<template>
  <Head>
    <title>Галерея {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Смотреть галерею фотографий интерьера и экстерьера жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА на официальном сайте."
    />
    <meta property="og:title" :content="'Галерея ' + $titleEnd" />
    <meta
      property="og:description"
      content="Смотреть галерею фотографий интерьера и экстерьера жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА на официальном сайте."
    />
  </Head>
  <main class="main mb-0">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'Галерея' }]"
      />
      <h1 class="page-title h2">Галерея</h1>
      <div ref="wall" class="wall">
        <masonry-wall
          :items="filteredItems"
          :column-width="392"
          :gap="24"
          @redraw="redraw"
        >
          <template #default="{ item, index }">
            <div v-if="item && item.type == 'image'" class="item">
              <div class="item__image">
                <a
                  :href="item.src"
                  class="stretched-link cursor-zoom"
                  @click.prevent="
                    $vfm.show({
                      component: 'PopupGallery',
                      bind: {
                        title: 'Галерея',
                        img: images,
                        index: index,
                      },
                    })
                  "
                >
                  <picture>
                    <source
                      v-if="item.image_tablet"
                      :srcset="item.image_tablet"
                      media="(min-width: 1921px)"
                    />
                    <source
                      v-if="item.image_mobile"
                      :srcset="item.image_mobile"
                      media="(min-width: 768px)"
                    />
                    <img
                      v-if="item.image_thumb || item.img"
                      :src="item.image_thumb || item.img"
                      class="cursor-zoom swiper-lazy"
                      loading="lazy"
                    />
                  </picture>
                </a>
              </div>
              <div v-if="item.text" class="item__text">
                {{ item.text }}
              </div>
            </div>
          </template>
        </masonry-wall>
      </div>
    </div>
    <HomeChoose />
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import HomeChoose from "@/components/home/Choose.vue";
import _shuffle from "lodash/shuffle";
import { is_touch } from "@/helpers.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
    HomeChoose,
  },
  inject: ["galleryStore"],
  data() {
    return {
      images: [],
      items: [],
    };
  },
  watch: {
    galleryStore: {
      handler() {
        this.initItems();
      },
      deep: true,
    },
  },
  created() {
    this.initItems();
  },
  computed: {
    filteredItems() {
      let items = _shuffle(this.items);
      this.getImages(items);
      return items;
    },
  },
  methods: {
    initItems() {
      this.items = this.galleryStore.state.data?.content[0]?.body?.blocks.map(
        (block) => {
          const data = block?.data[0];
          return {
            type: block.type,
            img: data.source,
            text: data.caption,
            image_desktop: data.image_desktop,
            image_tablet: data.image_tablet,
            image_mobile: data.image_mobile,
            image_thumb: data.image_thumb,
          };
        }
      );
    },
    redraw() {
      this.$nextTick(() => {
        ScrollTrigger.refresh(true);
        window.scrollTo(0, 0);

        if (!is_touch()) {
          gsap.from(".masonry-column", {
            y: 50,
            duration: 0.7,
          });
          gsap.to(".masonry-column", {
            y: (i, el) => (1 + i) * 0.3 * 400,
            scrollTrigger: {
              invalidateOnRefresh: true,
              trigger: this.$refs.wall,
              scrub: 1.5,
              start: "top",
              end: "bottom",
            },
          });
        }
      });
    },
    getImages(items) {
      let index = 0;
      items.forEach((item) => {
        if (item.type == "image") {
          item.index = index;
          this.images.push({
            src: item.img,
            image_desktop: item.image_desktop,
            image_tablet: item.image_tablet,
            image_mobile: item.image_mobile,
            image_thumb: item.image_thumb,
            text: item.text,
            index: index,
          });
          index++;
        }
      });
    },
  },
};
</script>

<style scoped>
.page-filter {
  max-width: 392px;
}

.item {
  position: relative;
}

.item__image img {
  width: 100%;
  height: auto;
}

.item__text {
  margin-top: 7px;
  color: #9c9b9b;
}

body.-notouch .wall {
  padding-bottom: 200px;
}

.masonry-wall {
  margin-bottom: 120px;
}

.masonry-wall :deep(.masonry-column:nth-child(2)) {
  margin-top: 145px;
}

.masonry-wall :deep(.masonry-column:nth-child(3)) {
  margin-top: -100px;
}
@media (max-width: 767px) {
  .masonry-wall {
    margin-bottom: 65px;
  }
}
</style>
